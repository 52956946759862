import React, { useContext } from "react"
import {
  Dimmer,
  Loader,
  Segment,
  Container,
  Header,
  Label,
} from "semantic-ui-react"
import { groupBy, Dictionary } from "lodash"
import dayjs from "dayjs"
import dayOfYear from "dayjs/plugin/dayOfYear"
import localizedFormat from "dayjs/plugin/localizedFormat"

import { FitoFutureBooking } from "../typings/FitoFutureBooking"
import { FitogramConfig } from "../Contexts/FitogramContext"
import { UserContext } from "../Contexts/UserContext"
import useWrappedAxios from "../hooks/useWrappedAxios"

dayjs.extend(dayOfYear)
dayjs.extend(localizedFormat)

const BookingDay: React.FC<{
  dayOfYear: number
  dayBookings: FitoFutureBooking[]
}> = props => {
  const day = dayjs().dayOfYear(props.dayOfYear)

  const getLocalizedTime = (time, format = "LT") => dayjs(time).format(format)

  const getMeta = (booking: FitoFutureBooking) => {
    const time = `${getLocalizedTime(booking.eventStartUTC)}-${getLocalizedTime(
      booking.eventEndUTC
    )}`

    if (booking.locationName.length < 1) {
      return time
    }

    return `${time}, ${booking.locationName}`
  }

  return (
    <>
      <Header as="h3">{day.format("LL")}</Header>
      <Segment.Group>
        {props.dayBookings.map(booking => (
          <Segment
            key={booking.id}
            disabled={booking.bookingStatus === "Cancelled"}
          >
            <Header as="h4">{booking.name}</Header>
            {booking.bookingStatus === "Cancelled" && (
              <Label horizontal>Annulé</Label>
            )}
            {getMeta(booking)}
          </Segment>
        ))}
      </Segment.Group>
    </>
  )
}

const transformResponse = (data: FitoFutureBooking[]) => {
  return groupBy(data, booking => dayjs(booking.eventStart).dayOfYear())
}

const MyBookingsTable: React.FC<{}> = props => {
  const { domain } = useContext(FitogramConfig)
  const { user } = useContext(UserContext)

  const [{ data, loading, error }, getBookings] = useWrappedAxios<
    Dictionary<FitoFutureBooking[]>
  >(
    {
      url: `/bookings/future`,
      params: {
        domain,
        includeglobal: false,
        page: 0,
        pageSize: 100,
      },
      transformResponse,
    },
    { manual: true }
  )

  React.useEffect(() => {
    if (user && user.auth_token && !data) {
      getBookings()
    }
  }, [user, data, getBookings])

  if (!user) {
    return <p>Veuillez vous connecter</p>
  }

  if (error) {
    return <p>Erreur pendant le chargement de vos réservations</p>
  }

  return (
    <Dimmer.Dimmable>
      <Dimmer inverted active={loading}>
        <Loader />
      </Dimmer>
      {data && (
        <Container>
          {Object.keys(data).map(day => (
            <>
              <BookingDay
                dayOfYear={parseInt(day, 10)}
                key={day}
                dayBookings={data[day]}
              ></BookingDay>
            </>
          ))}
        </Container>
      )}
    </Dimmer.Dimmable>
  )
}

export default MyBookingsTable
