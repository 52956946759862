import React from "react"

import { Layout } from "../layouts"
import SEO from "../components/seo"
import MyBookingsTable from "../components/MyBookingsTable"
import { Grid, Header } from "semantic-ui-react"

const MyBookingsPage: React.FC<{}> = props => {
  return (
    <Layout>
      <>
        <SEO title="Mes réservations" />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header as="h2">Prochaines réservations</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <MyBookingsTable />
          </Grid.Column>
        </Grid.Row>
      </>
    </Layout>
  )
}

export default MyBookingsPage
